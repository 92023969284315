define("ember-intl/-private/formatters/format-date", ["exports", "ember-intl/-private/formatters/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * @private
   * @hide
   */
  class FormatDate extends _base.default {
    format(intl) {
      for (var _len = arguments.length, _ref = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        _ref[_key - 1] = arguments[_key];
      }

      let [value, opts] = _ref;
      return intl.formatDate(value, opts);
    }

  }

  _exports.default = FormatDate;

  _defineProperty(FormatDate, "type", 'date');
});