define("ember-intl/helpers/format-date", ["exports", "ember-intl/helpers/-format-base"], function (_exports, _formatBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class _default extends _formatBase.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "allowEmpty", true);
    }

    format(value, options) {
      return this.intl.formatDate(value, options);
    }

  }

  _exports.default = _default;
});