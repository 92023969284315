define("ember-h-captcha/components/h-captcha", ["exports", "@glimmer/component", "ember-cached-decorator-polyfill"], function (_exports, _component, _emberCachedDecoratorPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="h-captcha" ...attributes {{did-insert this.initialize}}></div>
  */
  {
    "id": "2RYxufSb",
    "block": "[[[11,0],[24,0,\"h-captcha\"],[17,1],[4,[38,0],[[30,0,[\"initialize\"]]],null],[12],[13]],[\"&attrs\"],false,[\"did-insert\"]]",
    "moduleName": "ember-h-captcha/components/h-captcha.hbs",
    "isStrictMode": false
  });
  /* globals hcaptcha */


  let HCaptchaComponent = (_dec = Ember._action, (_class = class HCaptchaComponent extends _component.default {
    get config() {
      const _config = Ember.getOwner(this).resolveRegistration('config:environment') || {};

      return _config['ember-h-captcha'] || {};
    }

    get options() {
      let _options = {};
      Ember.assign(_options, this.config, this.componentOptions);
      return _options;
    }

    get componentOptions() {
      const defaults = ['sitekey', 'theme', 'size', 'tabindex'];
      const options = {};
      defaults.forEach(option => {
        if (Ember.isPresent(Ember.get(this.args, option))) {
          options[option] = Ember.get(this.args, option);
        }
      });
      return options;
    }

    initialize(element) {
      window.__ember_h_captcha_onload = () => {
        this.render(element);
      };

      this.appendScript([`${this.config['jsUrl'] || 'https://hcaptcha.com/1/api.js'}?render=explicit`, 'onload=__ember_h_captcha_onload', this.config['hl'] ? `hl=${this.config['hl']}` : ''].join('&'));
    }

    render(element) {
      const parameters = Ember.assign(this.options, {
        callback: this.onSuccessCallback.bind(this),
        'expired-callback': this.onExpiredCallback.bind(this),
        'error-callback': this.onErrorCallback.bind(this)
      });
      this.widgetId = hcaptcha.render(element, parameters);
      this.onRenderCallback();
    }

    onRenderCallback() {
      const action = this.args.onRender;

      if (Ember.isPresent(action) && typeof action === 'function') {
        action(this);
      }
    }

    appendScript(src) {
      let scriptTag = document.createElement('script');
      scriptTag.src = src;
      scriptTag.async = true;
      scriptTag.defer = true;
      document.body.appendChild(scriptTag);
    }

    onSuccessCallback(response) {
      const action = this.args.onSuccess;

      if (Ember.isPresent(action) && typeof action === 'function') {
        action(response);
      }
    }

    onExpiredCallback() {
      const action = this.args.onExpired;

      if (Ember.isPresent(action) && typeof action === 'function') {
        action();
      }
    }

    onErrorCallback(error) {
      const action = this.args.onError;

      if (Ember.isPresent(action) && typeof action === 'function') {
        action(error);
      }
    }

    reset() {
      if (Ember.isPresent(this.widgetId)) {
        hcaptcha.reset(this.widgetId);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "config", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "config"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "options", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "options"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "componentOptions", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "componentOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initialize", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "initialize"), _class.prototype)), _class));
  _exports.default = HCaptchaComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, HCaptchaComponent);
});