define("ember-href-to/helpers/href-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hrefTo = hrefTo;

  function hrefTo(routing, params) {
    return routing.generateURL(...getParamsForGenerateURL(params));
  }

  function getParamsForGenerateURL(params) {
    params = params.slice(); // create a copy

    let targetRouteName = params.shift(); // the first param is always the target route name

    let lastParam = params[params.length - 1]; // the last param might be queryParams

    let queryParams;

    if (lastParam && lastParam.isQueryParams) {
      queryParams = params.pop().values;
    } else {
      queryParams = {};
    }

    let models = params; // the remainder are the models

    return [targetRouteName, models, queryParams];
  }

  class HrefToHelper extends Ember.Helper {
    get routing() {
      return Ember.getOwner(this).lookup("service:-routing");
    }

    get router() {
      return Ember.getOwner(this).lookup("service:router");
    }

    init() {
      super.init();

      if (this.router && this.router.on) {
        // skip if the router service is mocked
        this.router.on("routeDidChange", this.recompute.bind(this));
      }
    }

    compute(params, namedArgs) {
      if (namedArgs.params) {
        return hrefTo(this.routing, namedArgs.params);
      } else {
        return hrefTo(this.routing, params);
      }
    }

  }

  _exports.default = HrefToHelper;
});