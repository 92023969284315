define("ember-promise-helpers/helpers/promise-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Helper {
    compute(params, hash) {
      return Ember.RSVP.hash(hash);
    }

  }

  _exports.default = _default;
});