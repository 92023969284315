define("ember-intl/-private/formatters/format-relative", ["exports", "ember-intl/-private/formatters/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * @private
   * @hide
   */
  class FormatRelative extends _base.default {
    format(intl, value, formatOptions) {
      (true && !(formatOptions) && Ember.assert(`[ember-intl] FormatRelative: Missing option`, formatOptions));
      const {
        format
      } = formatOptions;
      let unit = formatOptions.unit;
      let opts = formatOptions;

      if (!unit && format && intl.formats.relative && (opts = intl.formats.relative[format])) {
        unit = opts.unit;
      }

      (true && !(unit) && Ember.assert(`[ember-intl] FormatRelative: 'formatOptions' are missing a 'unit'. ${JSON.stringify(formatOptions)}`, unit));
      return intl.formatRelativeTime(value, unit, opts);
    }

  }

  _exports.default = FormatRelative;

  _defineProperty(FormatRelative, "type", 'relative');
});