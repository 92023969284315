define("ember-promise-helpers/helpers/promise-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Helper {
    compute(params) {
      const args = Array.isArray(params[0]) ? params[0] : params;
      return Ember.RSVP.all(args);
    }

  }

  _exports.default = _default;
});