define("ember-welcome-page/components/welcome-page", ["exports", "ember-welcome-page/templates/components/welcome-page"], function (_exports, _welcomePage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _welcomePage.default,
    isCurrent: Ember.computed(function () {
      let stableRegex = /^\d+\.\d+\.\d+$/;
      return !stableRegex.test(Ember.VERSION);
    }),
    canAngleBracket: Ember.computed(function () {
      return true;
    }),
    isModuleUnification: Ember.computed(function () {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config && config.isModuleUnification;
    }),
    rootURL: Ember.computed(function () {
      let config = Ember.getOwner(this).factoryFor('config:environment');

      if (config) {
        return config.class.rootURL;
      } else {
        return '/';
      }
    }),
    emberVersion: Ember.computed('isCurrent', function () {
      let isCurrent = Ember.get(this, 'isCurrent');

      if (isCurrent) {
        return 'current';
      } else {
        let [major, minor] = Ember.VERSION.split(".");
        return `${major}.${minor}.0`;
      }
    })
  });

  _exports.default = _default;
});