define("ember-intl/services/intl", ["exports", "ember-intl/-private/formatters", "ember-intl/-private/utils/is-array-equal", "ember-intl/-private/utils/normalize-locale", "ember-intl/-private/utils/get-dom", "ember-intl/-private/utils/hydrate", "@formatjs/intl", "ember-intl/-private/utils/flatten", "eventemitter3"], function (_exports, _formatters, _isArrayEqual, _normalizeLocale, _getDom, _hydrate, _intl, _flatten, _eventemitter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let _class = (_dec = Ember._dependentKeyCompat, _dec2 = Ember._tracked, _dec3 = Ember._tracked, (_class2 = class _class2 extends Ember.Service {
    /**
     * Returns an array of registered locale names
     *
     * @property locales
     * @public
     */
    get locales() {
      return Object.keys(this._intls);
    }
    /** @public **/


    set locale(localeName) {
      const proposed = Ember.makeArray(localeName).map(_normalizeLocale.default);

      if (!(0, _isArrayEqual.default)(proposed, this._locale)) {
        this._locale = proposed;
        Ember.run.cancel(this._timer);
        this._timer = Ember.run.next(() => {
          this._ee.emit('localeChanged');

          this._updateDocumentLanguage(this._locale);
        });
      }
    }

    get locale() {
      return this._locale;
    }
    /**
     * Returns the first locale of the currently active locales
     *
     * @property primaryLocale
     * @public
     */


    get primaryLocale() {
      return this.locale[0];
    }
    /** @public **/


    /** @public **/
    constructor() {
      super(...arguments);

      _defineProperty(this, "formatRelative", createFormatterProxy('relative'));

      _defineProperty(this, "formatMessage", createFormatterProxy('message'));

      _defineProperty(this, "formatNumber", createFormatterProxy('number'));

      _defineProperty(this, "formatTime", createFormatterProxy('time'));

      _defineProperty(this, "formatDate", createFormatterProxy('date'));

      _defineProperty(this, "formatList", createFormatterProxy('list'));

      _initializerDefineProperty(this, "_locale", _descriptor, this);

      _defineProperty(this, "_timer", null);

      _defineProperty(this, "_formats", null);

      _defineProperty(this, "_formatters", null);

      _initializerDefineProperty(this, "_intls", _descriptor2, this);

      _defineProperty(this, "_ee", null);

      _defineProperty(this, "_cache", (0, _intl.createIntlCache)());

      const initialLocale = this.locale || ['en-us'];
      this._intls = {};
      this._ee = new _eventemitter.default();
      this.setLocale(initialLocale);
      this._owner = Ember.getOwner(this);
      this._formatters = this._createFormatters();

      if (!this._formats) {
        this._formats = this._owner.resolveRegistration('formats:main') || {};
      }

      this.onIntlError = this.onIntlError.bind(this);
      this.getIntl = this.getIntl.bind(this);
      this.getOrCreateIntl = this.getOrCreateIntl.bind(this);
      (0, _hydrate.default)(this);
    }

    willDestroy() {
      super.willDestroy(...arguments);
      Ember.run.cancel(this._timer);
    }

    onIntlError(err) {
      if (err.code !== _intl.IntlErrorCode.MISSING_TRANSLATION) {
        throw err;
      }
    }
    /** @private **/


    onError(_ref) {
      let {
        /* kind, */
        error
      } = _ref;
      throw error;
    }
    /** @public **/


    lookup(key, localeName) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      const localeNames = this._localeWithDefault(localeName);

      let translation;

      for (let i = 0; i < localeNames.length; i++) {
        const messages = this.translationsFor(localeNames[i]);

        if (!messages) {
          continue;
        }

        translation = messages[key];

        if (translation !== undefined) {
          break;
        }
      }

      if (translation === undefined && options.resilient !== true) {
        const missingMessage = this._owner.resolveRegistration('util:intl/missing-message');

        return missingMessage.call(this, key, localeNames, options);
      }

      return translation;
    }
    /**
     * @private
     */


    getIntl(locale) {
      const resolvedLocale = Array.isArray(locale) ? locale[0] : locale;
      return this._intls[resolvedLocale];
    }

    getOrCreateIntl(locale, messages) {
      const resolvedLocale = Array.isArray(locale) ? locale[0] : locale;
      const existingIntl = this._intls[resolvedLocale];

      if (!existingIntl) {
        this._intls = { ...this._intls,
          [resolvedLocale]: this.createIntl(resolvedLocale, messages)
        };
      } else if (messages) {
        this._intls = { ...this._intls,
          [resolvedLocale]: this.createIntl(resolvedLocale, { ...(existingIntl.messages || {}),
            ...messages
          })
        };
      }

      return this._intls[resolvedLocale];
    }
    /**
     * @private
     * @param {String} locale Locale of intl obj to create
     */


    createIntl(locale) {
      let messages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const resolvedLocale = Array.isArray(locale) ? locale[0] : locale;
      return (0, _intl.createIntl)({
        locale: resolvedLocale,
        defaultLocale: resolvedLocale,
        formats: this._formats,
        defaultFormats: this._formats,
        onError: this.onIntlError,
        messages
      }, this._cache);
    }

    validateKeys(keys) {
      return keys.forEach(key => {
        (true && !(typeof key === 'string') && Ember.assert(`[ember-intl] expected translation key "${key}" to be of type String but received: "${typeof key}"`, typeof key === 'string'));
      });
    }
    /** @public **/


    t(key) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let keys = [key];

      if (options.default) {
        if (Array.isArray(options.default)) {
          keys = [...keys, ...options.default];
        } else if (typeof options.default === 'string') {
          keys = [...keys, options.default];
        }
      }

      this.validateKeys(keys);

      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        const message = this.lookup(key, options.locale, { ...options,
          // Note: last iteration will throw with the last key that was missing
          // in the future maybe the thrown error should include all the keys to help debugging
          resilient: keys.length - 1 !== index
        }); // @formatjs/intl consider empty message to be an error

        if (message === '' || typeof message === 'number') {
          return message;
        }

        if (message) {
          return this.formatMessage({
            id: key,
            defaultMessage: message
          }, options);
        }
      }
    }
    /** @public **/


    exists(key, localeName) {
      const localeNames = this._localeWithDefault(localeName);

      (true && !(Array.isArray(localeNames) && localeNames.length) && Ember.assert(`[ember-intl] locale is unset, cannot lookup '${key}'`, Array.isArray(localeNames) && localeNames.length));
      return localeNames.some(localeName => key in (this.getIntl(localeName)?.messages || {}));
    }
    /** @public */


    setLocale(locale) {
      (true && !(locale) && Ember.assert(`[ember-intl] no locale has been set!  See: https://ember-intl.github.io/ember-intl/docs/quickstart#4-configure-ember-intl`, locale));
      this.locale = locale;
      this.getOrCreateIntl(locale);
    }
    /** @public **/


    addTranslations(localeName, payload) {
      const locale = (0, _normalizeLocale.default)(localeName);
      this.getOrCreateIntl(locale, (0, _flatten.default)(payload));
    }
    /** @public **/


    translationsFor(localeName) {
      const locale = (0, _normalizeLocale.default)(localeName);
      return this.getIntl(locale)?.messages;
    }
    /** @private **/


    _localeWithDefault(localeName) {
      if (!localeName) {
        return this._locale || [];
      }

      if (typeof localeName === 'string') {
        return Ember.makeArray(localeName).map(_normalizeLocale.default);
      }

      if (Array.isArray(localeName)) {
        return localeName.map(_normalizeLocale.default);
      }
    }
    /** @private **/


    _updateDocumentLanguage(locales) {
      const dom = (0, _getDom.default)(this);

      if (dom) {
        const [primaryLocale] = locales;
        const html = dom.documentElement;
        html.setAttribute('lang', primaryLocale);
      }
    }
    /** @private */


    _createFormatters() {
      return {
        message: new _formatters.FormatMessage(),
        relative: new _formatters.FormatRelative(),
        number: new _formatters.FormatNumber(),
        time: new _formatters.FormatTime(),
        date: new _formatters.FormatDate(),
        list: new _formatters.FormatList()
      };
    }
    /**
     * @private
     * @param {Function} fn
     * @returns {Function} unsubscribed from localeChanged
     */


    onLocaleChanged() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._ee.on('localeChanged', ...args);

      return () => {
        this._ee.off('localeChanged', ...args);
      };
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "locale", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "locale"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "_locale", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "_intls", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class2));

  _exports.default = _class;

  function createFormatterProxy(name) {
    return function serviceFormatterProxy(value, formatOptions) {
      let locale;
      let intl;

      if (formatOptions && formatOptions.locale) {
        locale = this._localeWithDefault(formatOptions.locale); // Cannot use getOrCreateIntl since it triggers a re-render which
        // might cause infinite loop
        // This is also a case we're not optimizing for so let it take
        // the slow path

        intl = this.createIntl(locale);
      } else {
        locale = this.locale;
        intl = this.getIntl(locale);
      }

      return this._formatters[name].format(intl, value, formatOptions);
    };
  }
});