define("@ember-intl/decorators/intl", ["exports", "@ember-decorators/utils/decorator", "ember-intl"], function (_exports, _decorator, _emberIntl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-param-reassign, prefer-destructuring, @typescript-eslint/no-invalid-this */
  // eslint-disable-next-line func-names
  var _default = (0, _decorator.decoratorWithParams)(function intl(target, key, desc) {
    let dependentKeys = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    const value = desc.value;
    const initializer = desc.initializer;
    delete desc.value;
    delete desc.initializer;
    const cp = (0, _emberIntl.intl)(...dependentKeys, function (intl, // eslint-disable-line no-shadow
    propertyKey) {
      const fn = value || initializer.call(this);
      (true && !(typeof fn === 'function') && Ember.assert(`@intl: You need to decorate a function, but you decorated '${fn}'.`, typeof fn === 'function'));
      return fn.call(this, intl, propertyKey);
    }); // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    return cp(target, key, desc);
  });

  _exports.default = _default;
});