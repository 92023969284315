define("@jftechnology/ember-keycloak-auth/services/keycloak-session", ["exports", "keycloak-js"], function (_exports, _keycloakJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    Promise
  } = Ember.RSVP;
  /**
   * Injectable Ember service that wraps an application wide Keycloak js instance.
   *
   * @class KeycloakSessionService
   * @public
   */

  let KeycloakSessionService = (_dec = Ember.inject.service, _dec2 = Ember.computed('_keycloak', 'timestamp'), (_class = class KeycloakSessionService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "_keycloak", void 0);

      _defineProperty(this, "profile", void 0);

      _defineProperty(this, "minValidity", 30);

      _defineProperty(this, "ready", false);

      _defineProperty(this, "authenticated", false);

      _defineProperty(this, "timestamp", void 0);

      _defineProperty(this, "onLoad", 'login-required');

      _defineProperty(this, "responseMode", 'fragment');

      _defineProperty(this, "flow", 'standard');

      _defineProperty(this, "checkLoginIframe", false);

      _defineProperty(this, "checkLoginIframeInterval", 5);

      _defineProperty(this, "idpHint", void 0);

      _defineProperty(this, "verbose", false);

      _defineProperty(this, "onReady", authenticated => {
        Ember.set(this, 'ready', true);
        Ember.set(this, 'authenticated', authenticated);
        Ember.set(this, 'timestamp', new Date());
        console.info(`KeycloakSessionService :: onReady -> ${authenticated}`);
      });

      _defineProperty(this, "onAuthSuccess", () => {
        Ember.set(this, 'authenticated', true);
        Ember.set(this, 'timestamp', new Date());

        if (this.verbose) {
          console.debug(`KeycloakSessionService :: onAuthSuccess :: token -> ${this.token}`);
        }
      });

      _defineProperty(this, "onAuthError", errorData => {
        Ember.set(this, 'authenticated', false);
        Ember.set(this, 'timestamp', new Date());
        console.warn(`KeycloakSessionService :: onAuthError :: error -> ${errorData.error}, description -> ${errorData.error_description}`);
      });

      _defineProperty(this, "onAuthRefreshSuccess", () => {
        Ember.set(this, 'authenticated', true);
        Ember.set(this, 'timestamp', new Date());

        if (this.verbose) {
          console.debug(`KeycloakSessionService :: onAuthRefreshSuccess :: token -> ${this.token}`);
        }
      });

      _defineProperty(this, "onAuthRefreshError", () => {
        Ember.set(this, 'authenticated', false);
        Ember.set(this, 'timestamp', new Date());
        this.clearToken();
        console.warn('KeycloakSessionService :: onAuthRefreshError');
      });

      _defineProperty(this, "onTokenExpired", () => {
        Ember.set(this, 'authenticated', false);
        Ember.set(this, 'timestamp', new Date());
        console.info('KeycloakSessionService :: onTokenExpired');
      });

      _defineProperty(this, "onAuthLogout", () => {
        Ember.set(this, 'authenticated', false);
        Ember.set(this, 'timestamp', new Date());
        console.info('KeycloakSessionService :: onAuthLogout');
      });
    }

    /**
     * @method installKeycloak
     * @param {*[]} parameters Constructor parameters for Keycloak object - see Keycloak JS adapter docs for details
     */
    installKeycloak(parameters) {
      if (this.verbose) {
        console.debug('KeycloakSessionService :: install');
      }

      let keycloak = (0, _keycloakJs.default)(parameters);

      this._installKeycloak(keycloak);
    }

    _installKeycloak(keycloak) {
      keycloak.onReady = this.onReady;
      keycloak.onAuthSuccess = this.onAuthSuccess;
      keycloak.onAuthError = this.onAuthError;
      keycloak.onAuthRefreshSuccess = this.onAuthRefreshSuccess;
      keycloak.onAuthRefreshError = this.onAuthRefreshError;
      keycloak.onTokenExpired = this.onTokenExpired;
      keycloak.onAuthLogout = this.onAuthLogout;
      Ember.set(this, '_keycloak', keycloak);
      Ember.set(this, 'timestamp', new Date());

      if (this.verbose) {
        console.debug('KeycloakSessionService :: install :: completed');
      }
    }
    /**
     * @method initKeycloak
     */


    initKeycloak() {
      if (this.verbose) {
        console.debug('KeycloakSessionService :: init');
      }

      let options = this.getProperties('onLoad', 'responseMode', 'checkLoginIframe', 'checkLoginIframeInterval', 'flow');

      if (this.keycloak) {
        let keycloak = this.keycloak;
        return new Promise((resolve, reject) => {
          keycloak.init(options).then(authenticated => {
            console.info('KeycloakSessionService :: init complete');
            resolve(authenticated);
          }, reason => {
            console.warn('KeycloakSessionService :: init failed');
            reject(reason);
          });
        });
      }
    }
    /**
     * The wrapped Keycloak instance.
     *
     * @property keycloak
     * @type {Keycloak}
     */


    get keycloak() {
      return this._keycloak;
    }
    /**
     * The current Keycloak subject.
     *
     * @property subject
     * @type {string | undefined}
     */


    get subject() {
      return this.keycloak ? this.keycloak.subject : undefined;
    }
    /**
     * The current Keycloak refreshToken.
     *
     * @property refreshToken
     * @type {string}
     */


    get refreshToken() {
      return this.keycloak ? this.keycloak.refreshToken : undefined;
    }
    /**
     * The current Keycloak token.
     *
     * @property token
     * @type {string}
     */


    get token() {
      return this.keycloak ? this.keycloak.token : undefined;
    }
    /**
     * The current Keycloak tokenParsed.
     *
     * @property tokenParsed
     * @type {string}
     */


    get tokenParsed() {
      return this.keycloak ? this.keycloak.tokenParsed : undefined;
    }
    /**
     * Convenience property presents the current token as the Authorization header typically required by calls to a back end service.
     * @property headers
     * @type {Object}
     */


    get headers() {
      return {
        'Authorization': `Bearer ${this.token}`
      };
    }
    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method hasRealmRole
     * @param role {string} The role to check
     * @return {boolean} True if user in role.
     */


    hasRealmRole(role) {
      return !!(this.keycloak && this.keycloak.hasRealmRole(role));
    }
    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method hasResourceRole
     * @param role {string} The role to check
     * @param resource {string} The resource to check
     * @return {boolean} True if user in role.
     */


    hasResourceRole(role, resource) {
      return !!(this.keycloak && this.keycloak.hasResourceRole(role, resource));
    }

    inRole(role, resource) {
      if (role && resource) {
        return this.hasResourceRole(role, resource);
      }

      if (role) {
        return this.hasRealmRole(role);
      }

      return false;
    }
    /**
     * Delegates to the wrapped Keycloak instance's method using the minValidity property.
     *
     * @method updateToken
     * @return {Promise} Wrapped promise.
     */


    updateToken() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (this.keycloak) {
          this.keycloak.updateToken(this.minValidity).then(refreshed => {
            resolve(refreshed);
          }, () => {
            console.debug('update token resolved as error');
            reject(new Error('authentication token update failed'));
          });
        } else {
          reject(new Error("No installed keycloak instance"));
        }
      });
    }
    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method clearToken
     * @return {Promise} Wrapped promise.
     */


    clearToken() {
      if (this.keycloak) {
        this.keycloak.clearToken();
      }
    }
    /**
     * Parses the redirect url from the intended 'to' route of a transition.
     *
     * @method _parseRedirectUrl
     * @param {RouterService} router The ember router service.
     * @param {Transition} transition The transition in progress.
     * @return {String} URL to include as the Keycloak redirect
     * @private
     */


    _parseRedirectUrl() {
      // @ts-ignore
      console.debug(`KeycloakSessionService :: _parseRedirectUrl :: ${window.location.origin} + ${this.router.rootURL} + ${this.router.currentURL}`);
      let redirect = '/'; // @ts-ignore

      if (this.router.rootURL) {
        // @ts-ignore
        redirect = redirect + this.router.rootURL;
      }

      if (this.router.currentURL) {
        redirect = redirect + this.router.currentURL;
      }

      redirect = window.location.origin + redirect.replace(new RegExp('//', 'g'), '/');
      console.debug(`KeycloakSessionService :: _parseRedirectUrl :: ${redirect}`);
      return redirect;
    }
    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method loadUserProfile
     * @return {RSVP.Promise} Resolves on server response
     */


    loadUserProfile() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (this.keycloak) {
          this.keycloak.loadUserProfile().then(profile => {
            console.debug(`Loaded profile for ${profile.id}`);
            Ember.set(this, 'profile', profile);
            resolve(profile);
          }, error => {
            reject(error);
          });
        } else {
          reject(new Error("KeycloakSessionService :: no installed keycloak instance"));
        }
      });
    }
    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method login
     * @param {String} redirectUri Optional redirect url
     * @return {Promise} Resolves on server response
     */


    login(redirectUri) {
      let options = {
        redirectUri
      }; // Add idpHint to options, if it is populated

      if (this.idpHint) {
        options.idpHint = this.get('idpHint');
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        if (this.keycloak) {
          this.keycloak.login(options).then(() => {
            console.debug('KeycloakSessionService :: login :: success');
            resolve('login OK');
          }, () => {
            console.debug('KeycloakSessionService :: login error');
            reject(new Error('login failed'));
          });
        } else {
          reject(new Error("KeycloakSessionService :: no installed keycloak instance"));
        }
      });
    }
    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method logout
     * @param {String} redirectUri Optional redirect url
     * @return {RSVP.Promise} Resolves on server response.
     */


    logout(redirectUri) {
      let options = {
        redirectUri
      };
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (this.keycloak) {
          let keycloak = this.keycloak;
          keycloak.logout(options).then(() => {
            console.debug('KeycloakSessionService :: logout :: success');
            keycloak.clearToken();
            resolve('logout OK');
          });
        } else {
          reject(new Error("KeycloakSessionService :: no installed keycloak instance"));
        }
      });
    }

    wrappedCall(call) {
      return this.updateToken().then(result => {
        if (result && this.verbose) {
          console.debug(`KeycloakSessionService :: token was refreshed prior to wrapped call`);
        }

        return true;
      }).then(call, reason => {
        console.warn(`KeycloakSessionService :: update token :: rejected :: ${reason}`);

        let url = this._parseRedirectUrl();

        this.login(url);
        throw reason;
      });
    }
    /**
     * Keycloak callback function.
     *
     * @property onReady
     * @type {Function}
     */


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "keycloak", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "keycloak"), _class.prototype)), _class));
  _exports.default = KeycloakSessionService;
});