define("ember-intl/-private/formatters/-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  /**
   * @private
   * @hide
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  class FormatterBase {
    get options() {
      return [];
    }

  }

  _exports.default = FormatterBase;

  _defineProperty(FormatterBase, "type", void 0);
});