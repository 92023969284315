define("@jftechnology/ember-keycloak-auth/components/keycloak-session-config", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <table class="table table-sm small">
    <tbody>
      <tr>
        <th>minValidity</th>
        <td>{{this.keycloakSession.minValidity}}</td>
      </tr>
      <tr>
        <th>onLoad</th>
        <td>{{this.keycloakSession.onLoad}}</td>
      </tr>
      <tr>
        <th>responseMode</th>
        <td>{{this.keycloakSession.responseMode}}</td>
      </tr>
      <tr>
        <th>flow</th>
        <td>{{this.keycloakSession.flow}}</td>
      </tr>
      <tr>
        <th>checkLoginIframe</th>
        <td>{{this.keycloakSession.checkLoginIframe}}</td>
      </tr>
      <tr>
        <th>checkLoginIframeInterval</th>
        <td>{{this.keycloakSession.checkLoginIframeInterval}}</td>
      </tr>
      <tr>
        <th>idpHint</th>
        <td>{{this.keycloakSession.idpHint}}</td>
      </tr>
      <tr>
        <th>verbose</th>
        <td>{{this.keycloakSession.verbose}}</td>
      </tr>
    </tbody>
  </table>
  
  */
  {
    "id": "FrpKwksU",
    "block": "[[[10,\"table\"],[14,0,\"table table-sm small\"],[12],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"minValidity\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[30,0,[\"keycloakSession\",\"minValidity\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"onLoad\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[30,0,[\"keycloakSession\",\"onLoad\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"responseMode\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[30,0,[\"keycloakSession\",\"responseMode\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"flow\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[30,0,[\"keycloakSession\",\"flow\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"checkLoginIframe\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[30,0,[\"keycloakSession\",\"checkLoginIframe\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"checkLoginIframeInterval\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[30,0,[\"keycloakSession\",\"checkLoginIframeInterval\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"idpHint\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[30,0,[\"keycloakSession\",\"idpHint\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"verbose\"],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[30,0,[\"keycloakSession\",\"verbose\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "@jftechnology/ember-keycloak-auth/components/keycloak-session-config.hbs",
    "isStrictMode": false
  });

  /**
   * @class KeycloakSessionConfig
   * @public
   */
  let KeycloakSessionConfig = (_dec = Ember.inject.service, (_class = class KeycloakSessionConfig extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "keycloakSession", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "keycloakSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = KeycloakSessionConfig;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, KeycloakSessionConfig);
});