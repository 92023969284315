define("ember-validators/exclusion", ["exports", "ember-validators/utils/validation-error"], function (_exports, _validationError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateExclusion;

  /**
   *  @class Exclusion
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Boolean} options.allowBlank If true, skips validation if the value is empty
   * @param {Array} options.in The list of values this attribute should not be
   * @param {Array} options.range The range in which the attribute's value should not reside in
   * @param {Object} model
   * @param {String} attribute
   */
  function validateExclusion(value, options, model, attribute) {
    let array = options.in;
    let {
      range,
      allowBlank
    } = options;
    (true && !(!Ember.isEmpty(Object.keys(options))) && Ember.assert(`[validator:exclusion] [${attribute}] no options were passed in`, !Ember.isEmpty(Object.keys(options))));

    if (allowBlank && Ember.isEmpty(value)) {
      return true;
    }

    if (array && array.indexOf(value) !== -1) {
      return (0, _validationError.default)('exclusion', value, options);
    }

    if (range && range.length === 2) {
      let [min, max] = range;
      let equalType = Ember.typeOf(value) === Ember.typeOf(min) && Ember.typeOf(value) === Ember.typeOf(max);

      if (equalType && min <= value && value <= max) {
        return (0, _validationError.default)('exclusion', value, options);
      }
    }

    return true;
  }
});