define("@jftechnology/ember-keycloak-auth/components/keycloak-session-link", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.keycloakSession.ready}}
    {{#if this.keycloakSession.authenticated}}
      <a href="#" {{on "click" this.logout}}>
        <i class="fa fa-sign-out"></i> Sign out
      </a>
    {{else}}
      <a href="#" {{on "click" this.login}}>
        <i class="fa fa-sign-in"></i> Sign in
      </a>
    {{/if}}
  {{else}}
    No session
  {{/if}}
  
  */
  {
    "id": "OIkD+Xd6",
    "block": "[[[41,[30,0,[\"keycloakSession\",\"ready\"]],[[[41,[30,0,[\"keycloakSession\",\"authenticated\"]],[[[1,\"    \"],[11,3],[24,6,\"#\"],[4,[38,1],[\"click\",[30,0,[\"logout\"]]],null],[12],[1,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-sign-out\"],[12],[13],[1,\" Sign out\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,3],[24,6,\"#\"],[4,[38,1],[\"click\",[30,0,[\"login\"]]],null],[12],[1,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-sign-in\"],[12],[13],[1,\" Sign in\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"  No session\\n\"]],[]]]],[],false,[\"if\",\"on\"]]",
    "moduleName": "@jftechnology/ember-keycloak-auth/components/keycloak-session-link.hbs",
    "isStrictMode": false
  });

  /**
   * @class KeycloakSessionLink
   * @public
   */
  let KeycloakSessionLink = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class KeycloakSessionLink extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "keycloakSession", _descriptor, this);
    }

    login() {
      this.keycloakSession.login().then(result => {
        console.debug(result);
      });
    }

    logout() {
      this.keycloakSession.logout().then(result => {
        console.debug(result);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "keycloakSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "login", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "login"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logout", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype)), _class));
  _exports.default = KeycloakSessionLink;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, KeycloakSessionLink);
});