define("ember-math-helpers/helpers/log2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.log2 = log2;

  /**
   * Executes `Math.log2` on the number passed to the helper.
   *
   * ```hbs
   * {{log2 a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.log2`
   * @return {number} The log2 of the passed number
   */
  function log2(_ref) {
    let [number] = _ref;
    return Math.log2(number);
  }

  var _default = Ember.Helper.helper(log2);

  _exports.default = _default;
});