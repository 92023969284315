define("ember-intl/helpers/-format-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * @private
   * @hide
   */
  class AbstractHelper extends Ember.Helper {
    constructor() {
      super(...arguments);

      _defineProperty(this, "intl", null);

      _defineProperty(this, "unsubscribeLocaleChanged", null);

      if (this.constructor === AbstractHelper) {
        throw new Error('FormatHelper is an abstract class, can not be instantiated directly.');
      }

      this.intl = Ember.getOwner(this).lookup('service:intl');
      this.unsubscribeLocaleChanged = this.intl.onLocaleChanged(this.recompute, this);
    }

    format() {
      throw new Error('not implemented');
    }

    compute(_ref, options) {
      let [value] = _ref;

      if (Ember.isEmpty(value)) {
        if (options.allowEmpty ?? this.allowEmpty) {
          return;
        }

        if (typeof value === 'undefined') {
          throw new Error(`${this} helper requires value attribute.`);
        }
      }

      return this.format(value, options);
    }

    willDestroy() {
      super.willDestroy();
      this.unsubscribeLocaleChanged();
    }

  }

  _exports.default = AbstractHelper;
});