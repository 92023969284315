define("ember-intl/helpers/format-relative", ["exports", "ember-intl/helpers/-format-base"], function (_exports, _formatBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */
  class _default extends _formatBase.default {
    format(params, hash) {
      return this.intl.formatRelative(params, hash);
    }

  }

  _exports.default = _default;
});